.show-models-para{
    display: grid;
    grid-template-columns: 1fr 100px;
    gap: 30px;
    border-bottom: 1px dotted #bebbbb;
    padding: 10px;
}

.show-models-para .MuiSvgIcon-root:hover{
    background-color: #bebbbb;
    cursor: pointer;
}

.models-para-text {
    color: black;
    font-size: 14px;
    font-weight: 600;
}

.show-del-btn:hover {
    cursor: pointer;
    color: #6EA636;
}