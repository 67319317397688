.tts-button {
    background-color: transparent;
    border: none;
    padding: 0;
    margin-left: 1rem;
    /* position: absolute; */
    /* top: -22px; */
    /* right: 0; */
}

.tts-button span {
    font-size: 20px;
    font-weight: 600;
    color: #ca6c3a;
}

.tts-img{
    width: 20px;
}
